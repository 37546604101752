
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import DomainForm from "@/views/SslCerts/domain/DomainForm.vue";
import { mixins } from "vue-class-component";
import { SslCertStoreMixin } from "@/mixins/Stores/SslCertStoreMixin";
import AppConfig from "@/config/AppConfig";
import IDomain from "@/interfaces/IDomain";
import { DomainStoreMixin } from "@/mixins/Stores/DomainStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

const DomainModule = namespace("domain");

@Component({
  components: { DomainForm },
  mixins: [UpdateMixin],
})
export default class DomainUpdate extends mixins(
  DomainStoreMixin,
  SslCertStoreMixin
) {
  protected name = "DomainUpdate";

  @Prop({ required: false, default: false })
  protected isCheckingDomain!: boolean;

  protected loadSSlCertItem(): void {
    this.findAllSslCertItemsAction({
      resource:
        this.resource +
        "/" +
        this.$route.params.id +
        AppConfig.SSL_CERTIFICATE_RESOURCE,
    });
  }

  protected registerListeners(): void {
    this.$echo
      .channel("Domains")
      .listen("Domains\\DomainSslWasChecked", (e: any) => {
        if (
          e.sslCertificate &&
          this.getDomainItem?.ssl_certificate?.id === e.sslCertificate.id
        ) {
          const domainItem: IDomain = this.getDomainItem;

          domainItem.ssl_certificate = e.sslCertificate;

          this.setDomainItem(null);
          this.setDomainItem(domainItem);
        }
      });
  }

  protected init(): void {
    this.findOneDomainItemAction({
      id: this.$route.params.id,
      resource: this.resource,
    });
    this.loadSSlCertItem();
    this.registerListeners();
  }

  public get resource(): string {
    return AppConfig.DOMAIN_RESOURCE;
  }

  public get descriptionField(): string {
    return "url";
  }

  protected onCheckDomainClicked(): void {
    if (this.getDomainItem) {
      // this.checkDomain(this.getDomainItem);
      this.$emit("check-domain", this.getDomainItem);
    }
  }

  protected updateItemAction(options: ICrudOptions) {
    this.updateDomainItemAction(options);
  }
}
